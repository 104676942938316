<script setup>
import { onMounted, ref, computed } from 'vue'
import InventoryService from '@/apps/dailyTech/inventario-ti/services/inventory.service'
import SidebarDetailLogs from '@/apps/dailyTech/inventario-ti/views/profileInventory/activity/components/sidebarDetailLogs.vue'
import { useRoute } from 'vue-router'
import Icon from '@/components/Icon/index.vue'

const route = useRoute()
const _InventoryService = ref(new InventoryService())
const listActivityLogs = ref([])
const refSidebarDetailLogs = ref(null)

// Obtener el listado de actividad - logs
const fetchActivityLogs = async () => {
  try {
    const { id } = route.params
    const { data, status } = await _InventoryService.value.getLogsActivityInventory(id)

    if (status === 200) {
      listActivityLogs.value = data
    }
  } catch (error) {
    console.error('Error al obtener el listado de actividad - logs...', error)
  }
}

// Propiedad computada para generar icono y su color de acuerdo a la action
const dataLogs = computed(() => {
  if (listActivityLogs.value.records) {
    return listActivityLogs.value.records.map(log => {
      let icon, color, severity
      switch (log.action) {
        case 'CREADO':
          icon = 'mdi:file-plus'
          color = 'text-green-500'
          severity = 'success'
          break
        case 'ACTUALIZADO':
          icon = 'mdi:file-document-edit'
          color = 'text-yellow-500'
          severity = 'warn'
          break
        case 'ELIMINADO':
          icon = 'mdi:delete'
          color = 'text-red-500'
          severity = 'error'
          break
      }
      return { ...log, icon, color, severity }
    })
  } else {
    return []
  }
})

// Abrir sidebar del detalle de los logs
const onSidebarDetailsLogs = (data) => {
  refSidebarDetailLogs.value.openSidebarDetailLogs(data)
}

// Hook
onMounted(async () => {
  await fetchActivityLogs()
})
</script>

<template>
  <div class="box intro-x p-4">
    <h3 class="text-base text-gray-600 font-bold mb-2">Listado de actividad - logs</h3>

    <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1">
      <div class="col-span-1 sm:col-span-12 md:col-span-12">

        <DataTable
          ref="dt"
          :value="dataLogs"
          tableStyle="min-width: 50rem"
          class="p-datatable-sm text-xs"
          stripedRows
          dataKey="id"
          scrollable
          scrollHeight="620px"
        >
          <template #empty>
            <div class="flex flex-col items-center justify-center" style="height: 500px">
              <Icon icon="mdi:computer" class="text-gray-200" style="font-size: 15rem;"/>
              <span class="text-gray-400 text-2xl">No se encontraron registros</span>
              <span class="text-gray-300">No hemos encontrado ningún elemento, pruebe con otro filtro u opción</span>
            </div>
          </template>
          <template #loading> Cargando la información, por favor espere... </template>
          <Column field="id" header="ID" />
          <Column field="action" header="Acción">
            <template #body="{ data }">
              <div class="flex flex-wrap items-center">
                <Icon :icon="data.icon" :class="data.color" class="text-2xl mr-2" />
                <span>{{ data.action }}</span>
              </div>
            </template>
          </Column>
          <Column field="module" header="Módulo" />
          <Column field="createdBy" header="Usuario">
            <template #body="{ data }">
              <div class="flex flex-wrap items-center">
                <Icon icon="mdi:user" class="text-gray-500 text-2xl mr-2" />
                <span>{{ data.createdBy }}</span>
              </div>
            </template>
          </Column>
          <Column field="" header="Fecha y hora">
            <template #body="{ data }">
              <div class="flex flex-col">
                <div class="flex flex-wrap items-center">
                  <Icon icon="mdi:calendar-range" class="text-gray-500 text-2xl mr-2" />
                  <span>{{ data.date }}</span>
                </div>
                <div class="flex flex-wrap items-center">
                  <Icon icon="mdi:clock" class="text-gray-500 text-2xl mr-2" />
                  <span>{{ data.time }}</span>
                </div>
              </div>
            </template>
          </Column>
          <Column :exportable="false" header="Detalle">
            <template #body="{ data }">
              <Button
                severity="primary"
                class="pi"
                size="small"
                @click="onSidebarDetailsLogs(data)"
                v-tippy="{ content: 'Ver detalle' }"
              >
                <Icon icon="mdi:eye" class="text-base" />
              </Button>
            </template>
          </Column>
        </DataTable>
<!--        <pre style="font-size: 11px; line-height: 1"><code>{{ JSON.stringify(listActivityLogs, null, '\t') }}</code></pre>-->
      </div>
    </div>

  </div>

  <SidebarDetailLogs ref="refSidebarDetailLogs" />
</template>

<style scoped>

</style>
