<script setup>
import { computed, defineExpose, ref } from 'vue'
import Icon from '@/components/Icon/index.vue'
import DetailRow from '@/apps/dailyTech/inventario-ti/views/profileInventory/activity/components/detailRow.vue'
import SimpleTable from '@/apps/dailyTech/inventario-ti/views/profileInventory/activity/components/simpleTable.vue'
import ComparisonTable from '@/apps/dailyTech/inventario-ti/views/profileInventory/activity/components/comparisonTable.vue'

const infoDetailLogs = ref({})
const visible = ref(false)
const sidebarPosition = ref(window.innerWidth > 1024 ? 'right' : 'full')
const sidebarClass = ref(window.innerWidth > 1024 ? 'p-sidebar-md' : '')
const sidebarStyle = ref(window.innerWidth > 1024 ? 'width: 50vw' : '')

// Abrir el sidebar
const openSidebarDetailLogs = async (data) => {
  visible.value = true
  infoDetailLogs.value = data
}

// Funcion que se activa cuando cierra el sidebar
const onCloseSidebar = () => {
  visible.value = false
}

// Funcion para renombrar las llaves de los objetos
const renameKeys = (obj, newKeys) => {
  if (!obj) return {}
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [newKeys[key] || key, value]))
}

// Llaves para renombrar las llaves de los objetos
const keyNames = {
  typeDeviceName: 'Tipo de dispositivo',
  brandName: 'Marca',
  modelName: 'Modelo',
  locationName: 'Ubicación',
  statusName: 'Estado',
  ownerName: 'Propietario / Dueño'
}

// Propiedad computada para renombrar las llaves de los objetos
const newInfoDetailsLogs = computed(() => ({
  ...infoDetailLogs.value,
  generalNew: renameKeys(infoDetailLogs.value.generalNew, keyNames),
  generalOld: renameKeys(infoDetailLogs.value.generalOld, keyNames),
  attributesNew: infoDetailLogs.value.attributesNew || {},
  attributesOld: infoDetailLogs.value.attributesOld || {}
}))

//
window.addEventListener('resize', evt => {
  sidebarPosition.value = window.innerWidth > 1024 ? 'right' : 'full'
  sidebarClass.value = window.innerWidth > 1024 ? 'p-sidebar-md' : ''
  sidebarStyle.value = window.innerWidth > 1024 ? 'width: 50vw' : ''
})

// Se expone la funcion para abrir el sidebar, para que pueda ser llamado desde el componente padre
defineExpose({
  openSidebarDetailLogs
})
</script>

<template>
  <Sidebar
    v-model:visible="visible"
    :position="sidebarPosition"
    :baseZIndex="1000"
    :class="sidebarClass"
    :style="sidebarStyle"
    @hide="onCloseSidebar"
    :pt="{
      header: {
        style: 'display: flex; align-items: center; justify-content: space-between;'
      }
    }"
  >
    <template #header>
      <h3 class="text-gray-800 text-xl font-bold">Detalle #{{ infoDetailLogs.id }}</h3>
    </template>
    <div class="flex flex-col justify-between mb-4 w-full">
      <!-- Mensaje con la info global del log -->
      <Message
        :severity="infoDetailLogs.severity"
        :closable="false"
        :pt="{
          root: { style: 'margin: 0 0 0.5rem 0' },
          wrapper: { style: 'padding: 0.65rem 0.75rem;'},
          icon: { style: 'width: 1rem; height: 1rem' },
          text: { style: 'width: 100%; font-size: 0.8rem; display:flex; justify-content: between;' },
        }"
      >
        <div class="flex flex-col w-full">
          <DetailRow label="Acción" :value="infoDetailLogs.action" />
          <DetailRow label="Módulo" :value="infoDetailLogs.module" />
          <DetailRow label="Usuario" :value="infoDetailLogs.createdBy" />
          <DetailRow label="Fecha de creación" :value="infoDetailLogs.createdAt" />
        </div>
      </Message>
      <!--  -->
      <div v-if="newInfoDetailsLogs.action === 'CREADO' || newInfoDetailsLogs.action === 'ACTUALIZADO'">
        <!-- Informacion general -->
        <h3 class="text-base font-semibold mt-4 mb-2">Información General</h3>
        <!-- Tabla de comparación -->
        <ComparisonTable
          v-if="newInfoDetailsLogs.action === 'ACTUALIZADO'"
          :newData="newInfoDetailsLogs.generalNew"
          :oldData="newInfoDetailsLogs.generalOld"
        />
        <!-- Tabla simple -->
        <SimpleTable
          v-else
          :data="newInfoDetailsLogs.generalNew"
        />
        <!-- Mensaje si no hay cambios -->
        <div class="w-full flex flex-col justify-center items-center" v-if="newInfoDetailsLogs.action === 'ACTUALIZADO' && Object.keys(newInfoDetailsLogs.generalNew).length === 0">
          <Icon icon="fluent:edit-prohibited-20-filled" class="text-red-200 text-8xl opacity-20" />
          <span class="text-gray-300 text-lg font-semibold">No hay cambios en la información general</span>
        </div>
        <!-- Atributos -->
        <h3 class="text-base font-semibold mt-4 mb-2">Atributos</h3>
        <!-- Tabla de comparación -->
        <ComparisonTable
          v-if="newInfoDetailsLogs.action === 'ACTUALIZADO'"
          :newData="newInfoDetailsLogs.attributesNew"
          :oldData="newInfoDetailsLogs.attributesOld"
        />
        <!-- Tabla simple -->
        <SimpleTable
          v-else
          :data="newInfoDetailsLogs.attributesNew"
        />
        <!-- Mensaje si no hay cambios -->
        <div class="w-full flex flex-col justify-center items-center" v-if="newInfoDetailsLogs.action === 'ACTUALIZADO' && Object.keys(newInfoDetailsLogs.attributesNew).length === 0">
          <Icon icon="fluent:edit-prohibited-20-filled" class="text-red-200 text-8xl opacity-20" />
          <span class="text-gray-300 text-lg font-semibold">No hay cambios en los atributos</span>
        </div>
      </div>
<!--      <pre style="font-size: 11px; line-height: 1"><code>{{ JSON.stringify(newInfoDetailsLogs, null, '\t') }}</code></pre>-->
    </div>
  </Sidebar>
</template>

<style scoped>

</style>
